<template>
  <referentiel-list
    :listTitle="'Liste des Catégorie Rome'"
    :referentielName="referentielName"
    :tableFields="tableFields"
  >
    <template #formContent="{ handleInput, editableItem }">
      <div class="row">
        
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.code"
                @input="handleInput"
                type="text"
                class="form-control"
                id="code"
                name="code"
                placeholder="Code"
              />
              <label for="code">Code</label>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.nom"
                @input="handleInput"
                type="text"
                class="form-control"
                id="nom"
                name="nom"
                placeholder="nom"
              />
              <label for="nom">Nom</label>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
       
       <!-- <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <c-select
                id="famille"
                name="famille"
                placeholder="Famille"
                option-label="libelle"
                option-value="id"
                v-model="editableItem.famille"
                :options="familles"
                class="form-select"
              >
              </c-select>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
              <label for="famille">Famille</label>
            </div>
          </ValidationProvider>
        </div>-->
      </div>
    </template>
  </referentiel-list>
</template>

<script>
import ReferentielList from "../../../components/common/referentiel/ReferentielList.vue";
// import { mapGetters,mapActions } from "vuex";
export default {
  components: {
    ReferentielList,
  },
  computed: {
    // ...mapGetters({
    //     familles: 'famille/familles',
    // }),
    referentielName() {
      return "programme";
    },
    emptyReferentielItem () {
      return {
        libelle: null,
        code: null,
        // famille : null
      }
    },
    tableFields() {
      return [
        "#",
        "index",
        
        { key: "code", label: "Code" },
        { key: "nom", label: "Nom" },
      
        "actions",
      ];
    },
   
  },
  created() {
      this.fetchFamilles()
  },
  methods: {
    // ...mapActions({
    //   fetchFamilles: "famille/fetchFamilles",
    // }),
  },
};
</script>

<style></style>
